<template>
  <div class="auth-device-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
import {accessControlType2Model, getAccessControlTypes} from "@utils/acManager";

let validSerialNumber = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //仅支持输入大小写字母、数字，限制1~32位字符
    let reg = /^[A-z0-9]{1,32}$/;
    if (!reg.test(value)) {
      callback(new Error("SN地址格式不对"));
    } else {
      callback();
    }
  }
};
  export default {
    data() {
      let _this = this;
      return {
        options: [],
        opt: {
          title: "门禁列表",
          search: [{
              label: "省份",
              key: "province",
              type: "selectCity",
              opt: {
                list: [],
                change(data) {
                  _this.getCityList(data);
                }
              }
            },
            {
              label: "城市",
              key: "cityName",
              type: "selectCity",
              opt: {
                list: [],
                parent: "province",
                change(data) {
                  _this.getApartmentList(data);
                }
              }
            },
            {
              key: "apartmentName",
              label: "房源名称",
              type: "selectCity",
              opt: {
                list: [],
                parent: "cityName"
              }
            },
            {
              key: "serialNumber",
              label: "SN地址",
              maxlength: 32,
              rules: [{
                validator: validSerialNumber,
                trigger: ['blur']
              }]
            },
            {
              label: "门禁型号",
              key: "deviceCode",
              type: "select",
              opt: {
                list: []
              }
            }
          ],
          columns: [{
              label: "门禁型号",
              key: "deviceCodeName"
            },
            {
              label: "设备SN",
              key: "serialNumber",
              on: row => {
                _this.$router.push({
                  path:"/main/AccessControl/detail/show/" + row.id,
                  query:{ row, options : _this.options }
                })
              }
            },
            {
              label: "安装位置",
              key: "location"
            },
            {
              label: "房源名称",
              key: "apartmentName"
            },
            {
              label: "状态",
              key: "stateStr"
            }
          ],
          buttons: [{
              type: 0,
              on() {
                _this.$router.push("/main/AccessControl/detail/add/0");
              }
            },
            //   {
            //     type: 1,
            //     on(data) {
            //       console.log(data);
            //       _this.$router.push(
            //         "/main/AccessControl/detail/edit/" +
            //           data.data.id +
            //           "?tenantId=" +
            //           data.data.tenantId
            //       );
            //     }
            //   },
            {
              type: 2,
              on(data) {
                if (data.list.length > 1) {
                  return _this.$message({
                    showClose: true,
                    message: "不支持多个删除,只支持单个删除!",
                    type: "warning"
                  });
                }
                _this
                  .$confirm("此操作将删除选择项, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  })
                  .then(() => {
                    console.log(data);
                    console.log(data.list);
                    _this
                      .post("/landlord-service/house/v1/r20/unbind/" + data.list[0].serialNumber)
                      .then(function (res) {
                        _this.$message({
                          message: "删除成功",
                          type: "success"
                        });
                        data.refresh();
                      })
                      .catch(function (res) {
                        _this.$message({
                          message: res.data.msg,
                          type: "warning"
                        });
                      });
                  })
                  .catch(function () {});
              }
            }
          ]
        }
      };
    },
    activated() {
      this.onclickProvince();
      this.getApartmentList();
      this.getAccessControlTypeList()
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("landlord-service/house/v1/r20/page", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.stateStr = ["禁用", "正常"][item.state];
            item.deviceCodeName = accessControlType2Model(item.deviceCode);
          }
          opt.cb(res.data);
        });
      },
      //获取房源列表
      getApartmentList(cityId) {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          city: cityId
        };
        return new Promise((resolve, reject) => {
          this.post("landlord-service/apartment/queryPagesApartment", dto).then(
            res => {
              for (let i = 0; i < this.opt.search.length; i++) {
                let data = this.opt.search[i];
                if (data.key === "apartmentName") {
                  res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                  res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                  data.opt.list = res;
                  this.options = res
                  break;
                }
              }
            }
          );
        });
      },

      onclickProvince() {
        this.post("/landlord-service/base/getProvinces").then(res => {
          for (let i = 0; i < this.opt.search.length; i++) {
            let data = this.opt.search[i];
            if (data.key === "province") {
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              data.opt.list = res;
              break;
            }
          }
        });
      },
      // 获取城市列表
      getCityList(name) {
        this.post("/landlord-service/base/getCitys?name=" + name).then(res => {
          for (let i = 0; i < this.opt.search.length; i++) {
            let data = this.opt.search[i];
            if (data.key === "cityName") {
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              data.opt.list = res;
              break;
            }
          }
        });
      },
      // 获取门禁类型
      getAccessControlTypeList() {
        this.opt.search.find(data => data.key === 'deviceCode')
            .opt.list = getAccessControlTypes()
      }
    }
  };
</script>